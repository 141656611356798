<template>
  <div>
    <button
      class="ml-2 btn btn-primary"
      @click="oeffneMassenmutationsModal"
      v-if="berechtigungen.m_bildung.create"
      :disabled="!editable"
    >
      Massenmutation
    </button>
    <massenmutation-modal-generic
      :mutatableFields="this.mutatableFields"
      :mutatableDateFields="this.mutatableDateFields"
      :mutatableDateNames="this.mutatableDateNames"
      :mutatableTimeNames="this.mutatableTimeNames"
      :mutatableTimeFields="this.mutatableTimeFields"
      :mutatableValues="this.mutatableValues"
      :mutatableLabels="this.mutatableLabels"
      :mutatableCheckboxes="this.mutatableCheckboxes"
      :mutationPfad="this.mutationPfad"
      :selectedIds="this.selectedIds"
      :id="modalId"
      @mutated="mutated"
    />
  </div>
</template>
<script>
import MassenmutationModalGeneric from "@/components/Modals/MassenmutationModalGeneric";

export default {
  name: "MassenmutationButton",
  components: { MassenmutationModalGeneric },
  props: {
    mutatableFields: {
      type: Array,
    },
    mutatableDateFields: {
      type: Array,
    },
    mutatableDateNames: {
      type: Array,
    },
    mutatableTimeFields: {
      type: Array,
    },
    mutatableTimeNames: {
      type: Array,
    },
    mutatableValues: {
      type: Array,
    },
    mutatableLabels: {
      type: Array,
    },
    mutatableCheckboxes: {
      type: Array,
    },
    mutationPfad: {
      type: String,
    },
    selectedIds: {
      type: Array,
    },
  },
  data() {
    return {
      editable: false,
      modalId: "massenmutation-modal-generic",
    };
  },
  watch: {
    selectedIds(val) {
      val != 0 ? (this.editable = true) : (this.editable = false);
    },
  },
  methods: {
    oeffneMassenmutationsModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("open-modal", this.modalId);
      else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.norecordselected"),
        });
      }
    },
    mutated() {
      this.$emit("mutated");
    },
  },
};
</script>
