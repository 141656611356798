<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">Massenmutation</h4>
        </div>

        <ValidationObserver v-slot="{ invalid }">
          <div class="modal-body">
            <div class="row ml-0 mr-0">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <container-headline
                      headline="Zu mutierende Felder"
                      :col="6"
                    ></container-headline>
                    <div class="row mb-3">
                      <div class="col-xl-12 block br-t-l-0">
                        <div class="eingabe-panel-kopf p-3">
                          <div class="row">
                            <div
                              v-for="(field, index) in mutatableFields"
                              :key="field"
                              class="col-xl-6"
                            >
                              <div class="form-group">
                                <v-select
                                  v-model.trim="mutatedValues[field]"
                                  :options="mutatableValues[index][0]"
                                  :reduce="(v) => v.id"
                                  :label="mutatableLabels[index]"
                                  :placeholder="mutatableFields[index]"
                                >
                                </v-select>
                                <label>{{ mutatableFields[index] }}</label>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div
                              v-for="(field, index) in mutatableCheckboxes"
                              :key="field"
                              class="col-xl-4"
                            >
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  v-model="mutatedValues[field]"
                                />
                                <label class="form-check-label">{{
                                  mutatableCheckboxes[index]
                                }}</label>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div
                              v-for="(field, index) in mutatableDateFields"
                              :key="field"
                              class="col-xl-6"
                            >
                              <div class="form-group">
                                <date-picker
                                  date-format="dd.mm.yy"
                                  placeholder=" "
                                  :show-icon="true"
                                  @update="
                                    (val) =>
                                      (mutatedDateValues[
                                        mutatableDateNames[index]
                                      ] = val)
                                  "
                                />
                                <label>{{ mutatableDateFields[index] }}</label>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div
                              v-for="(field, index) in mutatableTimeFields"
                              :key="field"
                              class="col-xl-6"
                            >
                              <time-field
                                placeholder=" "
                                :label="$t('global.Time')"
                                @update="
                                  (val) =>
                                    (mutatedTimeValues[
                                      mutatableTimeNames[index]
                                    ] = val)
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="speichern"
              :disabled="invalid"
            >
              {{ $t("global.save") }}
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "@/mixins/Modal";
import Api from "@/Api";
import ContainerHeadline from "@/components/ContainerHeadline";
import DatePicker from "@/components/Datepicker";
import TimeField from "@/components/TimeField.vue";

export default {
  name: "MassenmutationModalGeneric",
  mixins: [modal],
  components: {
    ContainerHeadline,
    DatePicker,
    TimeField,
  },
  props: {
    id: {
      type: String,
    },
    objects: {
      type: Array,
    },
    mutatableFields: {
      type: Array,
    },
    mutatableDateFields: {
      type: Array,
    },
    mutatableDateNames: {
      type: Array,
    },
    mutatableTimeFields: {
      type: Array,
    },
    mutatableTimeNames: {
      type: Array,
    },
    mutatableValues: {
      type: Array,
    },
    mutatableLabels: {
      type: Array,
    },
    mutatableCheckboxes: {
      type: Array,
    },
    mutationPfad: {
      type: String,
    },
    selectedIds: {
      type: Array,
    },
  },
  data() {
    return {
      beitragId: null,
      subventionsbeitrag: {},
      mutatedValues: [],
      mutatedDateValues: [],
      mutatedTimeValues: [],
      shown: false,
    };
  },
  computed: {},
  watch: {},
  async created() {},
  mounted() {
    //this.$bus.$on("open-modal", this.openModal());
    this.$bus.$on("open-modal", (id) => {
      if (id == this.id && !this.shown) this.openModal();
    });
  },
  methods: {
    openModal() {
      this.shown = true;
      $("#" + this.id).modal("show");
    },
    inititalizeBeitrag(beitrag) {
      if (beitrag) {
        this.beitragId = beitrag.id;
        this.subventionsbeitrag = beitrag;
      } else this.clear();
    },
    clear() {
      console.log("clear");
      this.subventionsbeitrag = {};
    },

    beitragToJson() {
      return this.subventionsbeitrag;
    },

    speichern() {
      // this.mutatedDateValues.forEach(dateEntry => this.mutatedValues.push(dateEntry));
      if (this.mutatableDateNames && this.mutatableTimeNames) {
        this.mutatableDateNames.forEach(
          (element, index) =>
            (this.mutatedValues[element] = this.mutatedDateValues[element])
        );
        this.mutatableTimeNames.forEach(
          (element, index) =>
            (this.mutatedValues[element] = this.mutatedTimeValues[element])
        );
      }
      let json = Object.assign({}, this.mutatedValues);

      Api.put(this.mutationPfad, json, {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.closeModal();
        this.$emit("mutated");
      });
    },

    closeModal() {
      this.shown = false;
      this.clear();
      this.$emit("mutated");
      $("#" + this.id).modal("hide");
    },
  },
};
</script>

<style>
</style>
