<template>
  <div class="list">
    <navbar :title="headline"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row pt-4">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.bezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.kuerzel"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.shortdesignation") }}</label>
                    </div>
                  </div>
                </div>
                <div v-if="!this.kategorieProp" class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.veranstaltungstyp"
                        :options="veranstaltungstypen"
                        :reduce="(vt) => vt.id"
                        label="bezeichnung"
                        :placeholder="$t('dashboard.type')"
                      >
                        <span slot="no-options">{{
                          $t("global.notypefound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.eventtype") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.veranstaltungsstatus"
                        :options="veranstaltungsstati"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.status')"
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.eventstatus") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.verantwortlicher"
                        :options="verantwortliche"
                        :reduce="(v) => v.id"
                        label="personName"
                        :placeholder="$t('global.Responsible')"
                      >
                      </v-select>
                      <label>{{ $t("global.Responsible") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.semester"
                        :options="periode"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.period')"
                      >
                        <span slot="no-options">{{
                          $t("global.noperiodsfound")
                        }}</span>
                        <template v-slot:option="option">
                          {{ option.bezeichnung }} ({{ option.startdatum }}-{{
                            option.enddatum
                          }})
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          {{ option.bezeichnung }} ({{ option.startdatum }}-{{
                            option.enddatum
                          }})
                        </template>
                      </v-select>
                      <label>{{ $t("global.period") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="
                kategorieProp && kategorieObj
                  ? kategorieObj.bezeichnung
                  : $t('dashboard.education')
              "
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4">
                    <button
                      class="btn btn-success"
                      @click="neueVeranstaltung"
                      v-if="
                        berechtigungen.m_bildung.create &&
                        (this.kategorieProp ==
                          this.$CONST('VERANSTALTUNGSTYPEN').COURSE ||
                          this.kategorieProp ==
                            this.$CONST('VERANSTALTUNGSTYPEN').INFO ||
                          this.kategorieProp ==
                            this.$CONST('VERANSTALTUNGSTYPEN').EVENT)
                      "
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.new") }}
                    </button>
                  </div>
                  <div class="mr-4 float-right">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_bildung.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                    <template v-slot:cell(periode)="data">
                      <div v-if="data.value">
                        {{ data.value.bezeichnung }} ({{
                          data.value.startdatum
                        }}-{{ data.value.enddatum }})
                      </div>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>

            <div id="modal-loeschen" class="modal fade">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4>
                      <font-awesome-icon
                        icon="fa-regular fa-warning"
                        class="mr-2 text-warning"
                      />{{ $t("global.deleteevent") }}
                    </h4>
                  </div>
                  <div class="modal-body">
                    {{ $t("global.doyouwanttodeleteevents") }}
                  </div>
                  <div class="modal-footer">
                    <button
                      class="btn btn-default"
                      @click="schliesseLoeschenModal"
                    >
                      {{ $t("global.cancel") }}
                    </button>
                    <button class="btn btn-primary" @click="loeschen">
                      {{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <messagebox-count
      :headerText="$t('global.eventsdeleted')"
      id="count-delete-alert-msgbox"
      :ok="true"
      :cancel="false"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
    />
    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";

export default {
  name: "Bildungsliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        if (this.kategorieProp && this.veranstaltungstypen) {
          let kategorie = this.veranstaltungstypen.find(
            (r) => r.id === this.kategorieProp
          );
          if (kategorie) return kategorie.bezeichnung;
        }
        return "Bildung";
      },
    };
  },
  mixins: [page, dashboard, filter],
  props: {
    kategorieProp: {
      type: String,
      required: false,
      default: null,
    },
    topfklasseProp: {
      type: Boolean,
      requires: false,
      default: null,
    },
  },
  store,
  data() {
    return {
      verantwortliche: [],
      kategorie: null,

      sortBy: "bezeichnung",
      sortDesc: false,
      fields: [
        { key: "selected", label: "" },
        {
          key: "kuerzel",
          sortable: true,
          label: this.$t("global.shortdesignation"),
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "startdatum",
          label: this.$t("global.startdate"),
          tdClass: "text-center",
          thClass: "text-center",
          searchable: false,
        },
        {
          key: "enddatum",
          label: this.$t("global.enddate"),
          tdClass: "text-center",
          thClass: "text-center",
          searchable: false,
        },
        {
          key: "periode",
          sortable: true,
          label: this.$t("global.period"),
          searchable: false,
        },
        {
          key: "veranstaltungstyp.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("dashboard.type"),
        },
        {
          key: "veranstaltungsstatus.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.status"),
        },
        {
          key: "anmeldungen",
          label: this.$t("global.registrations"),
          tdClass: "text-center",
          thClass: "text-center",
          searchable: false,
        },
      ],
    };
  },
  computed: {
    queryParams: function () {
      var params = {}

      params.filterBezeichnung = this.filters.bezeichnung;
      params.filterKurzbezeichnung = this.filters.kuerzel;

      params.kategorie = this.kategorie;
      params.veranstaltungstyp = this.kategorieProp;
      params.status = this.filters.veranstaltungsstatus;
      params.verantwortlicher = this.filters.verantwortlicher;
      params.periode = this.filters.semester;

      // TODO:

      // params.filterVTBez = this.filters['veranstaltungstyp.bezeichnung']
      // params.filterVSBez = this.filters['veranstaltungsstatus.bezeichnung']

      if (this.filters.count && this.filters.count != "")
        params.filterCount = this.filters.count;

      return params;
    },

    periode: {
      get() {
        return this.$store.state.veranstaltung.perioden;
      },
    },
    veranstaltungstypen: {
      get() {
        return this.$store.state.veranstaltung.veranstaltungstypen;
      },
    },
    veranstaltungsstati: {
      get() {
        return this.$store.state.veranstaltung.veranstaltungsstati;
      },
    },

    kategorieObj() {
      if (this.kategorieProp && this.veranstaltungstypen)
        if (this.veranstaltungstypen.length > 0)
          return this.veranstaltungstypen.find(
            (r) => r.id === this.kategorieProp
          );

      return null;
    },

    headline() {
      if (this.kategorieProp && this.veranstaltungstypen) {
        if (this.veranstaltungstypen.length > 0)
          return this.veranstaltungstypen.find(
            (r) => r.id === this.kategorieProp
          ).bezeichnung;
      }
      return "Bildung";
    },
  },

  created() {
    /* Die Abfrage lassen wir mal vorerst hier und nicht im Store.
       Wir müssen klären wie häufig ein Verantwortlicher ändern könnte */

    Api.get("geschaeftspartner/", {
      params: {
        rollen: this.$CONST("ROLLEN").DOZENTEN.join(),
        count: 1000,
      },
    }).then((response) => {
      this.verantwortliche = response.data.sort(function (a, b) {
        return a.personName > b.personName;
      });
    });

    this.initFilter(
      "bildungs-liste-" + this.kategorieProp,
      "veranstaltungen/",
      true
    );
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
    if (this.veranstaltungstypen.length == 0) {
      this.$store.dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`);
    }

    this.setHeaders("global", [
      "",
      "shortdesignation",
      "designation",
      "startdate",
      "enddate",
      "period",
      "",
      "status",
      "registrations",
    ]);

    this.setHeaders("dashboard", ["", "", "", "", "", "", "type"]);
  },
  methods: {
    details(veranstaltungen) {
      if (
        !veranstaltungen.veranstaltungstyp ||
        veranstaltungen.veranstaltungstyp.id ==
          this.$CONST("VERANSTALTUNGSTYPEN").STUDIES
      ) {
        this.$router.push({
          name: "klasse",
          params: { klassenid: veranstaltungen.id, anzeige: 0 },
        });
      } else if (
        veranstaltungen.veranstaltungstyp.id ==
          this.$CONST("VERANSTALTUNGSTYPEN").COURSE ||
        veranstaltungen.veranstaltungstyp.id ==
          this.$CONST("VERANSTALTUNGSTYPEN").PRECOURSE
      ) {
        this.$router.push({
          name: "kurs",
          params: { kursid: veranstaltungen.id, anzeige: 0 },
        });
      } else if (
        veranstaltungen.veranstaltungstyp.id ==
        this.$CONST("VERANSTALTUNGSTYPEN").INFO
      ) {
        this.$router.push({
          name: "infoanlass",
          params: { infoanlassid: veranstaltungen.id, anzeige: 0 },
        });
      } else if (
        veranstaltungen.veranstaltungstyp.id ==
        this.$CONST("VERANSTALTUNGSTYPEN").EVENT
      ) {
        this.$router.push({
          name: "event",
          params: { eventid: veranstaltungen.id, anzeige: 0 },
        });
      } else if (
        veranstaltungen.veranstaltungstyp.id ==
          this.$CONST("VERANSTALTUNGSTYPEN").STUDIES_NDS ||
        veranstaltungen.veranstaltungstyp.id ==
          this.$CONST("VERANSTALTUNGSTYPEN").STUDIES_HFP
      ) {
        this.$router.push({
          name: "klasse",
          params: { klassenid: veranstaltungen.id, anzeige: 0 },
        });
      } else {
        console.log(veranstaltungen.veranstaltungstyp.id);
        console.log("Veranstaltungstyp nicht identifiziert");
      }
    },

    neueVeranstaltung() {
      //console.log(this.kategorieProp);
      if (this.kategorieProp == this.$CONST("VERANSTALTUNGSTYPEN").COURSE) {
        this.$router.push({
          name: "kurs",
          params: { anzeige: 0 },
        });
      } else if (
        this.kategorieProp == this.$CONST("VERANSTALTUNGSTYPEN").INFO
      ) {
        this.$router.push({
          name: "infoanlass",
          params: { anzeige: 0 },
        });
      } else if (
        this.kategorieProp == this.$CONST("VERANSTALTUNGSTYPEN").EVENT
      ) {
        this.$router.push({
          name: "event",
          params: { anzeige: 0 },
        });
      }
    },

    loeschen() {
      this.delete("veranstaltungen/", this.selectedIds);
    },

    schliesseLoeschenModal() {
      $("#modal-loeschen").modal("hide");
    },

    oeffneLoeschenModal() {
      $("#modal-loeschen").modal("show");
    },

    ausgebenStammblattPdf() {
      window.open(
        process.env.VUE_APP_SERVER_URL +
          `/pdf/stammblatt/pdf.act?ids=${this.selectedIds}`,
        "_blank"
      );
    },

    ausgebenPraesenzNotenPdf() {
      window.open(
        process.env.VUE_APP_SERVER_URL +
          `/pdf/praesenz-notenliste/pdf.act?ids=${this.selectedIds}`,
        "_blank"
      );
    },

    ausgebenAdressliste() {
      window.open(
        process.env.VUE_APP_SERVER_URL +
          `/pdf/teilnehmerliste-adressliste-anmeldung/pdf.act?ids=${this.selectedIds}`,
        "_blank"
      );
    },

    ausgebenPraesenzliste() {
      window.open(
        process.env.VUE_APP_SERVER_URL +
          `/pdf/praesenzliste/pdf.act?ids=${this.selectedIds}`,
        "_blank"
      );
    },

    ausgebenPraesenzlisteKW() {
      window.open(
        process.env.VUE_APP_SERVER_URL +
          `/pdf/praesenzliste-kw-anmeldung/pdf.act?ids=${this.selectedIds}`,
        "_blank"
      );
    },

    ausgebenTeilnehmerlisteAnmeldeantworten() {
      window.open(
        process.env.VUE_APP_SERVER_URL +
          `/pdf/teilnehmerliste-mit-anmeldeantworten/pdf.act?ids=${this.selectedIds}`,
        "_blank"
      );
    },
  },
};
</script>

<style>
</style>
