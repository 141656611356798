<template>
  <div class="row">
    <div
      :class="[colClass]"
      class="block-dark block-kopf-dark d-flex"
      :style="[
        isABBTS_Test()
          ? { 'background-color': '#248c8c' } // ABBTS-Testsystem Farbpalette
          : { 'background-color': '#124862' }, // reguläre Farbpalette
      ]"
    >
      <div class="primary-headline-text text-white pt-1">{{ headline }}</div>
      <div class="ml-auto text-white pt-1">
        <font-awesome-icon
          icon="fa-regular fa-magnifying-glass"
          class="p-1 clickable mr-2"
          @click="search()"
          size="xs"
        />
        <font-awesome-icon
          icon="fa-light fa-xmark"
          class="p-1 clickable"
          @click="reset()"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeadMenuFilter",
  props: {
    headline: {
      type: String,
      required: true,
    },
    col: {
      type: Number,
      required: false,
      default: 2,
    },
  },
  computed: {
    colClass: function () {
      if (this.col) {
        return "col-xl-" + this.col;
      } else {
        return "col-xl-2";
      }
    },
  },
  methods: {
    isABBTS_Test() {
      if (
        this.$CONST("CONFIG").SERVERURL ===
        "https://abbts-test.bx-education.ch/"
      ) {
        return true;
      } else {
        return false;
      }
    },

    search() {
      this.$bus.$emit("searchWithFilter");
    },
    reset() {
      this.$bus.$emit("resetFilter");
    },
  },
};
</script>

<style>
</style>
