<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <ValidationObserver v-slot="{ invalid }" :disabled="true">
          <div class="modal-header">
            <modal-close-button @confirmed="closeModal" />
            <h4 class="modal-title">{{ $t("global.createnewappointment") }}</h4>
          </div>
          <div class="modal-body">
            <div class="col-xl-12 pr-4">
              <!-- START Stammdaten -->
              <container-headline
                :headline="$t('global.masterdata')"
                :col="6"
              ></container-headline>
              <div class="row mb-3">
                <div class="col-xl-12 block br-t-l-0">
                  <div class="eingabe-panel-kopf p-3">
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="form-radio-group">
                          <div class="form-checkbox">
                            <input
                              type="checkbox"
                              class="mr-2"
                              v-model="infoscreen"
                            />
                            {{ $t("global.showoninfoscreen") }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="form-group">
                          <ValidationProvider
                            rules="required"
                            immediate
                            v-slot="{ passed }"
                          >
                            <input
                              v-model.trim="bezeichnung"
                              class="form-control"
                              v-bind:class="{ 'border-danger': !passed }"
                              placeholder=" "
                            />
                            <label v-bind:class="{ 'text-danger': !passed }">{{
                              $t("global.designation")
                            }}</label>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="form-group">
                          <v-select
                            v-model="ressourcenstatus"
                            label="bezeichnung"
                            :options="ressourcenstati"
                            :reduce="(s) => s.id"
                            :placeholder="$t('global.status')"
                          >
                            <span slot="no-options">{{
                              $t("notification.taptosearch")
                            }}</span>
                          </v-select>
                          <label>{{ $t("global.reservationstatus") }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="form-group">
                          <v-select
                            v-model="planungsstatus"
                            label="bezeichnung"
                            :options="planungsstati"
                            :placeholder="$t('global.status')"
                          >
                            <span slot="no-options">{{
                              $t("notification.taptosearch")
                            }}</span>
                          </v-select>
                          <label>{{ $t("global.planningstatus") }}</label>
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="form-group">
                          <v-select
                            v-model="durchfuehrungsstatus"
                            label="bezeichnung"
                            :options="durchfuehrungsstati"
                            :placeholder="$t('global.status')"
                          >
                            <span slot="no-options">{{
                              $t("notification.taptosearch")
                            }}</span>
                          </v-select>
                          <label>{{ $t("global.executionstatus") }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="form-radio-group">
                          <input
                            type="radio"
                            id="repeating-no"
                            value="false"
                            v-model="repeating"
                          />
                          <label for="repeating-no" class="ml-2">{{
                            $t("global.onetime")
                          }}</label>

                          <input
                            type="radio"
                            id="repeating-yes"
                            value="true"
                            v-model="repeating"
                            class="ml-2"
                          />
                          <label for="repeating-yes" class="ml-2">{{
                            $t("global.recurring")
                          }}</label>
                        </div>
                      </div>

                      <div class="col-xl-4" v-if="repeating == 'true'">
                        <div class="form-group">
                          <v-select
                            v-model="periodizitaet"
                            label="bezeichnung"
                            :options="periodizitaeten"
                            :reduce="(r) => r.id"
                            :placeholder="$t('global.periodicity')"
                          >
                            <span slot="no-options">{{
                              $t("notification.taptosearch")
                            }}</span>
                          </v-select>
                          <label>{{ $t("global.periodicity") }}</label>
                        </div>
                      </div>
                      <div class="col-xl-2" v-if="repeating == 'true'">
                        <div class="form-group">
                          <input
                            v-model.number="repeatcount"
                            class="form-control"
                            placeholder=""
                          />
                          <label>{{ $t("global.Number") }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-3">
                        <div class="form-group">
                          <ValidationProvider rules="required" immediate>
                            <input v-model="von" type="hidden" />
                            <date-picker
                              date-format="dd.mm.yy"
                              :initial="von"
                              placeholer=""
                              :show-icon="true"
                              @update="updateVon"
                              :inModal="true"
                            />
                            <label>{{ $t("global.datstart") }}</label>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="col-xl-3">
                        <ValidationProvider rules="required" immediate>
                          <time-field
                            :label="$t('global.starttime')"
                            placeholder=""
                            :initial="von_uhrzeit"
                            @update="(val) => (von_uhrzeit = val)"
                          />
                        </ValidationProvider>
                      </div>

                      <div class="col-xl-3" v-if="repeating == 'false'">
                        <div class="form-group">
                          <ValidationProvider rules="required" immediate>
                            <date-picker
                              date-format="dd.mm.yy"
                              :initial="bis"
                              placeholder=" "
                              :show-icon="true"
                              @update="updateBis"
                            />
                            <label>{{ $t("global.dateend") }}</label>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="col-xl-3">
                        <ValidationProvider rules="required" immediate>
                          <time-field
                            placeholder=""
                            :label="$t('global.endtime')"
                            :initial="bis_uhrzeit"
                            @update="(val) => (bis_uhrzeit = val)"
                          />
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="form-group">
                          <input
                            v-model.number="lektionen"
                            class="form-control"
                            placeholder=""
                          />
                          <label
                            >{{ $t("global.lessons") }}
                            <!-- {{ dozent1PersonName }} --></label
                          >
                        </div>
                      </div>
                      <div class="col-xl-6"></div>
                    </div>
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="form-group">
                          <v-select
                            v-model="dozenten"
                            label="personName"
                            :options="person"
                            :placeholder="$t('global.lecturer')"
                            :reduce="(r) => r.id"
                            multiple
                            @confirmed="setVerantwortlicher"
                          >
                            <span slot="no-options">{{
                              $t("notification.taptosearch")
                            }}</span>
                          </v-select>
                          <label>{{ $t("global.lecturer") }}</label>
                        </div>
                      </div>

                      <div class="col-xl-6">
                        <single-select-person
                          id="termin-reserviert-durch"
                          :person="reserviert_durch"
                          :label="$t('global.reservedby')"
                          :allowOpen="false"
                          :rollen="filterRollenMitarbeiter"
                          :editable="true"
                          :required="true"
                          @confirmed="setReserviertDurch"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="form-group">
                          <v-select
                            v-model="ressourcenbuchung"
                            label="bezeichnung"
                            :options="ressourcenbuchungstypen"
                            :reduce="(r) => r.id"
                            :placeholder="$t('global.externalinternal')"
                          >
                            <span slot="no-options">{{
                              $t("notification.taptosearch")
                            }}</span>
                          </v-select>
                          <label>{{ $t("global.resourcebooking") }}</label>
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="form-group">
                          <v-select
                            v-model="raumliste"
                            label="bezeichnung"
                            :options="raeume"
                            :reduce="(r) => r.id"
                            :placeholder="$t('global.room')"
                            multiple
                          >
                            <span slot="no-options">{{
                              $t("notification.taptosearch")
                            }}</span>
                          </v-select>
                          <label>{{ $t("global.room") }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-12">
                        <div class="form-group">
                          <textarea
                            v-model.trim="bemerkung"
                            class="form-control"
                            placeholder=" "
                            rows="4"
                          ></textarea>
                          <label>{{ $t("global.comment") }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ENDE Stammdaten -->
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              :disabled="invalid"
              class="btn btn-primary"
              @click="speichern"
            >
              {{ $t("global.save") }}
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
    <loading-overlay v-if="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import ContainerHeadline from "@/components/ContainerHeadline";
import DatePicker from "@/components/Datepicker";

import ModalCloseButton from "@/components/global/ModalCloseButton";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import TimeField from "@/components/TimeField.vue";
import LoadingOverlay from "@/components/global/LoadingOverlay";

import { LADE_AUSWAHLWERTE_RESSOURCEN } from "@/store/ressourcen/actions.type";

import { textNotLinked } from "@/defaultTexts.js";

export default {
  name: "NeuerTerminModal",
  components: {
    ContainerHeadline,
    DatePicker,
    ModalCloseButton,
    SingleSelectPerson,
    TimeField,
    LoadingOverlay,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    veranstaltung: {
      type: String,
      required: false,
    },
    raum: {
      type: String,
      required: false,
    },
    planungsstati: {
      type: Array,
      required: false,
    },
    durchfuehrungsstati: {
      type: Array,
      required: false,
    },
    dozentenbuchungen: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      shown: false,

      verantwortlicher: { personName: textNotLinked },
      reserviert_durch: { personName: textNotLinked },
      planungsstatus: { id: "17574932559", bezeichnung: "Bestätigung" },
      durchfuehrungsstatus: { id: "175748EC2CB", bezeichnung: "Offen" },
      bezeichnung: null,
      bis: new Intl.DateTimeFormat("de-CH").format(new Date()),
      bis_uhrzeit: null,
      von: new Intl.DateTimeFormat("de-CH").format(new Date()),
      von_uhrzeit: null,
      repeating: "false",
      infoscreen: true,
      periodizitaet: this.$CONST("PERIODIZITAETEN").WEEKLY,
      repeatcount: null,
      dozenten: [],
      bemerkung: null,
      ressourcenbuchung: this.$CONST("RESSOURCENBUCHUNGEN").INTERNAL,
      ressourcenstatus: this.$CONST("RESSOURCENSTATI").RESERVED,

      lektionen: null,

      raumliste: null,

      filterRollenDozenten: this.$CONST("ROLLEN").DOZENTEN,
      filterRollenMitarbeiter: this.$CONST("ROLLEN").MITARBEITER,
    };
  },
  computed: {
    ressourcenstati: {
      get() {
        return this.$store.state.ressourcen.ressourcenstati;
      },
    },
    ressourcenbuchungstypen: {
      get() {
        return this.$store.state.ressourcen.ressourcenbuchungstypen;
      },
    },
    raeume: {
      get() {
        return this.$store.state.ressourcen.raeume;
      },
    },
    periodizitaeten: {
      get() {
        return this.$store.state.ressourcen.periodizitaeten;
      },
    },
    mitarbeiter: {
      get() {
        return this.$store.state.mitarbeiter;
      },
    },
    person: function () {
      let personen = [];
      this.dozentenbuchungen.forEach((db) => personen.push(db.person));
      return personen;
    },

    dozentenGewaehlt: function () {
      return this.findeDozentenbuchungenAufTermin(this.dozenten);
    },

    /*     dozent1PersonName: function () { // wird aktuell nicht gebraucht
      let nameDozent1 = "";

      if (this.dozenten.length > 0)
        nameDozent1 = this.dozentenGewaehlt[0].person.personName;

      return nameDozent1;
    }, */
  },
  watch: {
    von: function () {
      this.bis = this.von;
    },
  },
  mounted() {
    if (this.ressourcenstati.length == 0) {
      this.$store.dispatch(`ressourcen/${LADE_AUSWAHLWERTE_RESSOURCEN}`);
    }

    this.$bus.$on("openNeuerTerminModal", (id) => {
      if (!this.shown) this.openModal(id);
    });
  },
  methods: {
    updateVon(val) {
      this.von = val;
      if (this.bis == null || this.bis == "") this.bis = val;
    },

    updateBis(val) {
      this.bis = val;
    },

    openModal(id) {
      this.shown = true;

      const thisId = id ? id : this.id;

      if (this.raum) this.raumliste = [this.raum];

      if (this.veranstaltung) {
        Api.get("veranstaltungen/", {
          params: { id: this.veranstaltung },
        }).then((response) => {
          this.bezeichnung = response.data.bezeichnung;
        });
      }

      this.reserviert_durch = this.mitarbeiter.person;

      $("#" + thisId).modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
    },

    setVerantwortlicher(dozenten) {
      console.log("setverantwortliche" + dozenten);
      this.dozenten = this.findeDozentenbuchungenAufTermin(this.dozenten);
    },

    setReserviertDurch(person) {
      this.reserviert_durch = person;
    },
    findeDozentenbuchungenAufTermin(dozenten) {
      let dozentenbuchungenAufTermin = [];

      dozenten.forEach((doz) => {
        console.log(doz);
        let db = this.dozentenbuchungen.find((e) => {
          console.log(e);
          return e.person.id === doz;
        });
        dozentenbuchungenAufTermin.push(db);
      });

      return dozentenbuchungenAufTermin;
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;
      var json = {
        action: "speichern",
        veranstaltung: this.veranstaltung,
        ressourcenstatus: this.ressourcenstatus,
        planungsstatus: this.planungsstatus,
        durchfuehrungsstatus: this.durchfuehrungsstatus,
        bezeichnung: this.bezeichnung,
        bis: this.bis,
        bis_uhrzeit: this.bis_uhrzeit,
        von: this.von,
        von_uhrzeit: this.von_uhrzeit,
        bemerkung: this.bemerkung,
        infoscreen: this.infoscreen,
        reserviert_durch: this.reserviert_durch.id,
        verantwortlich: this.verantwortlicher.id,
        ressourcenbuchung: this.ressourcenbuchung,
        lektionen: this.lektionen,
        dozenten: this.dozenten,
        dozentenbuchungen: this.dozentenGewaehlt,
        repeating: this.repeating == "false" ? false : true,
        periodizitaet: this.periodizitaet,
        repeatcount: this.repeatcount == null ? 1 : this.repeatcount,
      };

      json.raumliste = this.raumliste
        ? this.raumliste.map((raum) => (raum.id ? raum.id : raum))
        : null;

      Api.post("termin/", json)
        .then((response) => {
          if(response.data.konflikte[0].length == 0){
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("notification.appointmentsuccessfullycreated"),
          });
          } else {
          this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.roomisalreadyreserved"),
          })   
          }
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.$parent.getTermine();
          this.loading = false;
          this.closeModal();
        });
    },
  },
};
</script>

<style>
</style>
