<template>
  <div class="list">
    <notifications></notifications>
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>

            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                  <div class="col-xl-12">
                    <single-select-person
                      id="tor-von"
                      :person="filters.student"
                      :label="$t('global.person')"
                      :allowOpen="false"
                      :activeonly="false"
                      editable
                      @confirmed="setFilterPerson"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.degree"
                        :options="degrees"
                        :reduce="(s) => s.id"
                        label="designation"
                        :placeholder="$t('global.degree')"
                      >
                        <span slot="no-options">{{
                          $t("global.nodegreefound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.degree") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.event"
                        :options="events"
                        :reduce="(s) => s.id"
                        label="designation"
                        :placeholder="$t('global.event')"
                      >
                        <span slot="no-options">{{
                          $t("global.noeventound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.event") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.period"
                        :options="periods"
                        :reduce="(s) => s.id"
                        label="designation"
                        :placeholder="$t('global.period')"
                      >
                        <span slot="no-options">{{
                          $t("global.noperiodsfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.period") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.size"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="'Transcript of Records'"
              :anzahl="anzahlDaten"
              :anzahlTotal="anzahlTotal"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <!--<div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row mt-2 d-flex">
                  <div class="ml-4" style="display: flex">
                    <button
                      class="btn btn-primary mr-2"
                      v-if="berechtigungen.m_anmeldungen.update"
                      @click="oeffneAbschlussWaehlenModal()"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-award"
                        class="mr-2"
                      />
                      <span>Abschlüsse Simulieren</span>
                    </button>
                  </div>
                  <div class="ml-1" style="display: flex">
                    <button
                      class="btn btn-primary mr-2"
                      v-if="berechtigungen.m_anmeldungen.update"
                      @click="notenUebernehmen()"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-arrow-right-to-bracket"
                        class="mr-2"
                      />
                      <span>Noten übernehmen</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>-->
            <div class="row block belegliste belegliste-small">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-compare="sortDates"
                    sort-icon-left
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <!--
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>
					

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
					-->
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading-overlay v-show="loading"></loading-overlay>
    <tor-abschluss-waehlen-modal
      :id="'tor-abschluss-waehlen-modal'"
      :abschluesse="degrees"
      @setAbschluss="setAbschlussSimulation"
    />
    <tor-abschluss-simulieren-modal
      :id="'tor-abschluss-simulieren-modal'"
      :abschluss="abschlussSimulation"
    />
  </div>
</template>
  
  <script>
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import Tor from "@/Tor";
import store from "@/store";
import page from "@/mixins/Page";
import torDashboard from "@/mixins/TorDashboard";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import TorAbschlussWaehlenModal from "@/components/Tor/TorAbschlussWaehlenModal";
import TorAbschlussSimulierenModal from "@/components/Tor/TorAbschlussSimulierenModal";

export default {
  name: "TorListe",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    SingleSelectPerson,
    TorAbschlussWaehlenModal,
    TorAbschlussSimulierenModal,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + "ToR";
      },
    };
  },
  mixins: [page, torDashboard],
  props: {},
  store,
  data() {
    return {
      sortBy: "lastname",
      sortDesc: false,
      fields: [
        //{ key: "selected", label: "" },
        {
          key: "number",
          sortable: true,
          searchable: false,
          label: this.$t("global.number"),
        },
        {
          key: "lastname",
          sortable: true,
          searchable: false,
          label: this.$t("global.surname"),
        },
        {
          key: "name",
          sortable: true,
          searchable: false,
          label: this.$t("global.firstname"),
        },
      ],
      periods: [],
      events: [],
      degrees: [],
      abschlussSimulation: {},
    };
  },
  computed: {
    queryParams: function () {
      var params = {};

      params.student = this.setQueryParam(this.filters.student?.id);
      params.period = this.setQueryParam(this.filters.period);
      params.event = this.setQueryParam(this.filters.event);
      params.degree = this.setQueryParam(this.filters.degree);

      if (this.filters.size && this.filters.size != "") {
        params.size = this.filters.size;
      }

      return params;
    },
  },
  watch: {},
  created() {
    this.initFilter("tor-liste", "students/", true);
    this.holeAuswahlwerte();
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
    this.setHeaders("global", [
      //"",
      "number",
      "surname",
      "firstname",
    ]);
  },
  methods: {
    holeAuswahlwerte() {
      //Abschluesse
      Tor.get("degrees/", {})
        .then((response) => {
          this.degrees = response.data;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        });

      //Perioden
      Tor.get("periods/", {})
        .then((response) => {
          this.periods = response.data;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        });

      //Veranstaltungen
      Tor.get("events/", {})
        .then((response) => {
          this.events = response.data;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        });
    },
    refresh() {
      this.resetLoading(this);
    },
    details(geschaeftspartner) {
      this.$router.push({
        name: "geschaeftspartner",
        params: { id: geschaeftspartner.id, anzeige: 17 },
      });
    },

    setFilterPerson(student) {
      this.$set(this.filters, "student", student);
    },
    oeffneAbschlussWaehlenModal() {
      this.$bus.$emit("openAbschlussWaehlenModal", {
        modalId: "tor-abschluss-waehlen-modal",
      });
    },
    oeffneAbschlussSimulierenModal() {
      this.$bus.$emit("openAbschlussSimulierenModal", {
        modalId: "tor-abschluss-simulieren-modal",
      });
    },
    notenUebernehmen() {
      this.loading = true;

      Tor.post("grades/", {})
        .then(() => {
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: "Noten erfolgreich übernommen.",
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setAbschlussSimulation(abschluss) {
      this.abschlussSimulation = abschluss;
      this.oeffneAbschlussSimulierenModal();
    },
  },
};
</script>
  
<style>
</style>
  