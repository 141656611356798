<template>
  <div id="qualifikation-bearbeiten-modal" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">
            {{
              editQualifikation.id
                ? $t("global.editqualification")
                : $t("global.addqualification")
            }}
          </h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <v-select
                  v-model="qtyp"
                  label="bezeichnung"
                  placeholder="Qualifikationstyp"
                  :options="qualifikationtypen"
                  :clearable="false"
                ></v-select>
                <label>{{ $t("global.qualifications") }}</label>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="form-group">
                <v-select
                  v-model="niveau"
                  label="bezeichnung"
                  :options="qualifikationniveau"
                  :clearable="false"
                ></v-select>
                <label>{{ $t("global.level") }}</label>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  :initial="editQualifikation.datum"
                  placeholder=" "
                  :show-icon="true"
                  :inModal="true"
                  @update="(val) => (editQualifikation.datum = val)"
                />
                <label>{{ $t("global.date")}}</label>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="form-group">
                <input
                  v-model.trim="editQualifikation.ort"
                  class="form-control"
                  placeholder=" "
                />
                <label>{{ $t("global.city") }}</label>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="form-group">
                <textarea
                  v-model.trim="editQualifikation.bemerkung"
                  class="form-control"
                  placeholder=" "
                ></textarea>
                <label>{{ $t("global.comment") }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="closeModal">
            {{ $t("global.cancel") }}
          </button>
          <button type="button" class="btn btn-primary" @click="uebernehmen">
            {{ $t("global.apply") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCloseButton from "@/components/global/ModalCloseButton";
import DatePicker from "@/components/Datepicker";

export default {
  name: "QualifikationModal",
  components: { ModalCloseButton, DatePicker },
  props: {},
  data() {
    return {
      editQualifikation: {},
      qtyp: null,
      niveau: null,
      shown: false,
    };
  },
  computed: {
    qualifikationtypen() {
      return this.$store.state.geschaeftspartner.qualifikationtypen;
    },
    qualifikationniveau() {
      return this.$store.state.geschaeftspartner.qualifikationniveau;
    },
  },
  watch: {
    qtyp: {
      handler: function (val) {
        this.editQualifikation.qualifikationtyp = val;
      },
    },
    niveau: {
      handler: function (val) {
        this.editQualifikation.niveau = val;
      },
    },

    /* qualifikationen() {
      console.log("qualifikationen");
      if (
        this.qualifikationtypen.length > 0 &&
        (!this.editQualifikation.qualifikationtyp ||
          !this.editQualifikation.qualifikationtyp.id)
      ) {
        this.editQualifikation.qualifikationtyp = this.qualifikationtypen[0];
      }

      if (
        this.qualifikationniveau.length > 0 &&
        (!this.editQualifikation.niveau || !this.editQualifikation.niveau.id)
      ) {
        this.editQualifikation.niveau = this.qualifikationniveau[0];
      }
    },*/
  },
  mounted() {
    this.$bus.$on("qualifikation-modal-show", (data) => {
      this.editQualifikation = Object.assign({}, data);

      this.qtyp = this.editQualifikation.qualifikationtyp;
      this.niveau = this.editQualifikation.niveau;

      if (!this.qtyp && this.qualifikationtypen.length > 0) {
        this.qtyp = this.qualifikationtypen[0];
        this.editQualifikation.qualifikationtyp = this.qualifikationniveau[0];
      }

      if (!this.niveau && this.qualifikationniveau.length > 0) {
        this.niveau = this.qualifikationniveau[0];
        this.niveau.qualifikationniveau = this.qualifikationniveau[0];
      }

      if (!this.shown) this.openModal();
    });
  },

  methods: {
    openModal() {
      this.shown = true;

      $("#qualifikation-bearbeiten-modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      $("#qualifikation-bearbeiten-modal").modal("hide");
    },

    uebernehmen() {
      if(!this.editQualifikation.datum) {
        this.editQualifikation.datum = "kein Datum"
      }
      if (this.editQualifikation.id || this.editQualifikation.key) {
        this.$emit("qchanged", this.editQualifikation);
      } else {
        this.$emit("qcreated", this.editQualifikation);
      }

      this.closeModal();
    },
  },
};
</script>

<style>
</style>
