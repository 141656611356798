<template>
  <div class="list">
    <navbar title="Subvention"></navbar>
    <div class="container-fluid">
      <div class="row">
        <notifications></notifications>
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.person"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.person") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.bildungsgang"
                        :options="ausbildungen"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('dashboard.educationcourse')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.noeducationcoursefound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("dashboard.educationcourse") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.subventionsstatus"
                        :options="subventionsstati"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.subsidystatus')"
                      >
                        <span slot="no-options">{{
                          $t("global.nosubsidystatusfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.status") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.ausbildungsstatus"
                        :options="ausbildungsstati"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.statuseducation')"
                      >
                        <span slot="no-options">{{
                          $t("global.noeducationstatusofsubsidiesfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.statuseducation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.subventionskanton"
                        :options="subventionskantone"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.subsidycanton')"
                      >
                        <span slot="no-options">{{
                          $t("global.nosubsidycantonfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.subsidycanton") }}</label>
                    </div>
                  </div>
                </div>
                <!--
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.subventionsbeitrag"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>Subventionsbeitrag</label>
                    </div>
                  </div>
                </div>
                -->
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-ausbildung-von"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.ausbildungsbeginnVon"
                        field="ausbildungsbeginnVon"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.startofeducationfrom") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-ausbildung-bis"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.ausbildungsbeginnBis"
                        field="ausbildungsbeginnBis"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.startofeducationuntil") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-ausbildungende-von"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.ausbildungsendeVon"
                        field="ausbildungsendeVon"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.endofeducationfrom") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-ausbildungende-bis"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.ausbildungsendeBis"
                        field="ausbildungsendeBis"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.endofeducationuntil") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-kostengutsprache-von"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.kostengutspracheVon"
                        field="kostengutspracheVon"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.costapprovalfrom") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-kostengutsprache-bis"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.kostengutspracheBis"
                        field="kostengutspracheBis"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.costapprovalupto") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12 my-2">
                    <div class="form-group-inline" style="margin-left: 7px">
                      <label
                        class="float-left"
                        style="
                          font-size: 11px !important;
                          font-weight: 700 !important;
                        "
                        >{{ $t("global.newentry") }}: </label
                      ><input
                        class="form-check-input float-right mx-3"
                        style="margin-top: 0.2rem"
                        :value="true"
                        v-model="filters['neueintritt']"
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.bemerkung"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.remarks") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.subsidylist')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4">
                    <!-- Massenmutationsbutton -->
                    <div style="display: inline-flex">
                      <massenmutation-button
                        class="mr-2"
                        :mutatableFields="this.mutatableFields"
                        :mutatableValues="[
                          [this.subventionsstati],
                          [this.ausbildungsstati],
                        ]"
                        :mutatableLabels="this.mutatableLabels"
                        :mutatableCheckboxes="this.mutatableCheckboxes"
                        :mutationPfad="this.mutationPfad"
                        :selectedIds="this.selectedIds"
                        @mutated="refresh"
                      />
                    </div>
                    <!-- Neue Subvention Button -->
                    <router-link
                      v-if="berechtigungen.b_bildung_bildungsportfolio.create"
                      class="btn btn-success"
                      :to="{
                        name: 'neue-subvention',
                      }"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.newsubsidy") }}
                    </router-link>
                    <!--
                    <button
                      class="btn btn-primary ml-2"
                      @click="oeffneMassenmutationsModal"
                      v-if="berechtigungen.b_bildung_bildungsportfolio.update"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-rectangle-history"
                        class="mr-2"
                      />
                      Massenmutation
                    </button>-->
                    <button
                      class="btn btn-primary ml-2"
                      @click="oeffneSubventionsbeitragErstellenModal"
                      v-if="berechtigungen.b_bildung_bildungsportfolio.update"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-money-check-alt"
                        class="mr-2"
                      />
                      {{ $t("global.createposts") }}
                    </button>
                    <!--<button
                      class="btn btn-primary ml-2"
                      @click="oeffneAlertModal"
                    >
                      Excel Export
                    </button>-->
                  </div>

                  <div class="mr-4">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.b_bildung_bildungsportfolio.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-compare="sortDatumSubvention"
                    :subvention="subventionsstati"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <!--
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>
                    -->

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <massenmutation-modal
      id="massenmutation-modal"
      :ids="selectedIds"
      :objects="selectedObjects"
    />

    <subventionsbeitrag-erstellen-modal
      id="subventionsbeitrag-erstellen-modal"
      :subventionsstati="subventionsstati"
      :ausbildungsstati="ausbildungsstati"
    />
    <alert-modal
      id="alert-excelexport-modal"
      :title="propAlertTitle"
      :text="propAlertText"
      :color="propAlertColor"
      :eventName="propAlertEventName"
      @subventionenExportieren="subventionenExportieren"
    />

    <loeschen-modal @confirmed="loeschen" :multiple="true" />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import DatePicker from "@/components/Datepicker";
import FileDownload from "js-file-download";
import MassenmutationModal from "@/components/Modals/MassenmutationModal";
import AlertModal from "@/components/Modals/AlertModal";
import SubventionsbeitragErstellenModal from "@/components/Modals/SubventionsbeitragErstellenModal";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import MassenmutationButton from "@/components/Buttons/MassenmutationButton";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

import LoeschenModal from "@/components/Modals/LoeschenModal";

import { LADE_AUSWAHLWERTE_SUBVENTION } from "@/store/subvention/actions.type";

export default {
  name: "subventionsliste",
  components: {
    AlertModal,
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    DatePicker,
    MassenmutationModal,
    SubventionsbeitragErstellenModal,
    MassenmutationButton,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.subsidies");
      },
    };
  },
  mixins: [page, dashboard, filter],
  data() {
    return {
      sortBy: "bezeichnung",
      sortDesc: false,
      propAlertId: "alert-excelexport-modal",
      propAlertTitle: "Subventionen exportieren",
      propAlertColor: "",
      propAlertEventName: "subventionenExportieren",
      fields: [
        { key: "selected", label: "" },
        {
          key: "person.personName",
          sortable: true,
          label: this.$t("global.person"),
          searchable: false,
        },
        {
          key: "ausbildung.bezeichnung",
          sortable: true,
          label: this.$t("dashboard.educationcourse"),
          searchable: false,
        },
        {
          key: "ausbildungsbeginn",
          sortable: true,
          label: this.$t("global.startofeducation"),
          searchable: false,
        },
        {
          key: "ausbildungsende",
          sortable: true,
          label: this.$t("global.endofeducation"),
          searchable: false,
        },
        {
          key: "subventionsstatus.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.subsidystatus"),
        },
        {
          key: "ausbildungstatus.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.statuseducation"),
        },
        {
          key: "subventionskanton.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.subsidycanton"),
        },
        {
          key: "subventionsbeitraege[0].betrag",
          searchable: false,
          sortable: true,
          label: this.$t("global.subsidycontribution"),
        },
        {
          key: "bemerkung",
          searchable: false,
          sortable: true,
          label: this.$t("global.comment"),
        },
      ],

      mutatableFields: ["Status", "Bildungsstatus"],
      mutatableValues: [[], []],
      mutatableLabels: ["bezeichnung", "bezeichnung"],
      mutatableCheckboxes: ["Neueintritt"],
      mutationPfad: "bildung/subvention/",
    };
  },
  computed: {
    queryParams: function () {
      var params = {};

      params.filterPerson = this.filters.person;
      //params.filterBildungsgang = this.filters["bildungsgang.bezeichnung"];

      if (this.bildungsgang) params.filterBildungsgang = this.bildungsgang;
      else
        params.filterBildungsgang = this.filters.bildungsgang
          ? this.filters.bildungsgang.join(",")
          : null;

      params.filterSubventionsstatus = this.filters.subventionsstatus;
      params.filterSubventionskanton = this.filters.subventionskanton;
      params.filterAusbildungsstatus = this.filters.ausbildungsstatus;

      if (
        this.filters.ausbildungsbeginnVon &&
        this.filters.ausbildungsbeginnVon != ".."
      )
        params.filterAusbildungsbeginnVon = this.filters.ausbildungsbeginnVon;

      if (
        this.filters.ausbildungsbeginnBis &&
        this.filters.ausbildungsbeginnBis != ".."
      )
        params.filterAusbildungsbeginnBis = this.filters.ausbildungsbeginnBis;

      if (
        this.filters.ausbildungsendeVon &&
        this.filters.ausbildungsendeVon != ".."
      )
        params.filterAusbildungsendeVon = this.filters.ausbildungsendeVon;

      if (
        this.filters.ausbildungsendeBis &&
        this.filters.ausbildungsendeBis != ".."
      )
        params.filterAusbildungsendeBis = this.filters.ausbildungsendeBis;

      if (
        this.filters.kostengutspracheVon &&
        this.filters.kostengutspracheVon != ".."
      )
        params.filterKostengutspracheVon = this.filters.kostengutspracheVon;
      if (
        this.filters.kostengutspracheBis &&
        this.filters.kostengutspracheBis != ".."
      )
        params.filterKostengutspracheBis = this.filters.kostengutspracheBis;

      params.filterBemerkung = this.filters.bemerkung;

      params.filterNeueintritt = this.filters.neueintritt;
      if (this.filters.count != "") params.filterCount = this.filters.count;

      return params;
    },

    subventionsstati: {
      get() {
        return this.$store.state.subvention.subventionsstati;
      },
    },
    subventionskantone: {
      get() {
        return this.$store.state.subvention.subventionskantone;
      },
    },
    ausbildungsstati: {
      get() {
        return this.$store.state.subvention.ausbildungstati;
      },
    },
    abschluesse: {
      get() {
        return this.$store.state.subvention.abschlusse;
      },
    },
    ausbildungen: {
      get() {
        return this.$store.state.subvention.ausbildungen;
      },
    },
    propAlertText: function () {
      let anzahlDatensaetze = this.selectedIds.length;
      return anzahlDatensaetze + " Einträge werden exportiert ";
    },
  },

  created() {
    this.initFilter("bildungsportfolio-liste", "bildung/subvention/", true);
  },

  mounted() {
    this.$bus.$emit("searchWithFilter");
    if (this.subventionsstati.length == 0)
      this.$store.dispatch(`subvention/${LADE_AUSWAHLWERTE_SUBVENTION}`);
    this.setHeaders("global", [
      "",
      "person",
      "",
      "startofeducation",
      "endofeducation",
      "subsidystatus",
      "statuseducation",
      "subsidycanton",
      "subsidycontribution",
      "comment",
    ]);
    this.setHeaders("dashboard", [
      "",
      "",
      "educationcourse",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ]);
  },
  methods: {
    details(subvention) {
      this.$router.push({
        name: "subvention",
        params: { subventionid: subvention.id, anzeige: 0 },
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0) {
        this.$bus.$emit("openLoeschenModal");
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noeducationportfolioselected"),
        });
      }
    },

    oeffneMassenmutationsModal() {
      this.$bus.$emit("open-modal", "massenmutation-modal");
    },

    oeffneSubventionsbeitragErstellenModal() {
      this.$bus.$emit("open-modal", "subventionsbeitrag-erstellen-modal");
    },
    oeffneAlertModal() {
      this.$bus.$emit("open-modal", "alert-excelexport-modal");
    },
    subventionenExportieren() {
      if (this.selectedObjects.length > 0) {
        let json = [];
        //let date = new Date();
        json.push(...this.selectedObjects);

        Api.post("subvention/excelexport/", json, { responseType: "blob" })
          .then((response) => {
            let excelFile = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            FileDownload(excelFile, "Subventionen.xlsx");
            this.loading = false;
          })
          .catch((error) => {
            this.$notify(apiErrorToAlert(error));
            /* setTimeout(() => {
              this.$notify({
                type: "error",
                title: "Aktion fehlgeschlagen",
                text: "Fehler beim Erstellen der Excel-Datei \n -> Excel-Format überprüfen. Datei erneut hochladen.",
                duration: 7000,
              });
            }, 2000); */
            this.loading = false;
          })
          .then(() => {
            this.resetLoading(this);
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
            this.resetLoading(this);
          });
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.nosubsidies"),
        });
      }
    },

    loeschen() {
      Api.delete("bildung/subvention/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.resetLoading(this);
      });
    },

    sortDatumSubvention(datumA, datumB, key) {
      if (key != "ausbildungsbeginn" && key != "ausbildungsende") {
        return false;
      } else {
        let a = null;
        let b = null;
        if (key.includes(".")) {
          a = this.resolveTermin(key, datumA);
          b = this.resolveTermin(key, datumB);
        } else {
          a = datumA[key];
          b = datumB[key];
        }
        if (a && b) {
          a = a.split(".");
          b = b.split(".");
          a =
            parseInt(a[2], 10) * 10000 +
            parseInt(a[1], 10) * 100 +
            parseInt(a[0]);
          b =
            parseInt(b[2], 10) * 10000 +
            parseInt(b[1], 10) * 100 +
            parseInt(b[0]);
          return a - b;
        } else {
          return false;
        }
      }
    },
    resolveTermin(path, obj = self, separator = ".") {
      var properties = Array.isArray(path) ? path : path.split(separator);
      return properties.reduce((prev, curr) => prev && prev[curr], obj);
    },
    refresh() {
      this.resetLoading(this);
    },
  },
};
</script>

<style>
</style>
