<!-- Diese Liste dient zur Ausgabe aller Anmeldungen für eine Veranstaltung -->
<template>
  <div class="list">
    <notifications></notifications>
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>

            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                  <div class="col-xl-12">
                    <single-select-person
                      id="anmeldung-von"
                      :person="filters.person"
                      :label="$t('global.person')"
                      :allowOpen="false"
                      :rollen="filterRollen"
                      :activeonly="false"
                      editable
                      @confirmed="setFilterPerson"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.geschlecht"
                        :options="geschlechter"
                        label="bezeichnung"
                        :placeholder="$t('global.gender')"
                      >
                      </v-select>
                      <label>{{ $t("global.gender") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.abschluss"
                        :options="abschluesse"
                        :reduce="(ab) => ab.id"
                        label="bezeichnung"
                        :placeholder="$t('global.degree')"
                      >
                        <span slot="no-options">{{
                          $t("global.nodegreefound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.degree") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <single-select-veranstaltung
                      id="anmeldung-klasse-veranstaltung"
                      :veranstaltung="filters.veranstaltung"
                      :label="$t('global.event')"
                      :allowOpen="false"
                      editable
                      @confirmed="setFilterVeranstaltung"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.veranstaltungsstatus"
                        :options="veranstaltungsstati"
                        :reduce="(vs) => vs.id"
                        label="bezeichnung"
                        :placeholder="$t('global.eventstatus')"
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.eventstatus") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.veranstaltungstyp"
                        :options="veranstaltungstypen"
                        :reduce="(vt) => vt.id"
                        label="bezeichnung"
                        :placeholder="$t('global.typeofevent')"
                      >
                        <span slot="no-options">{{
                          $t("global.notypesfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.typeofevent") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.statusAnmeldung"
                        :options="anmeldestati"
                        :reduce="(vt) => vt.id"
                        label="bezeichnung"
                        :placeholder="$t('global.status')"
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.registrationstatus") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.anmeldungstyp"
                        :options="anmeldetypen"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('dashboard.type')"
                      >
                        <span slot="no-options">{{
                          $t("global.notypefound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.typeofregistration") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.semester"
                        :options="semester"
                        :reduce="(p) => p.id"
                        :placeholder="$t('global.period')"
                      >
                        <span slot="no-options">{{
                          $t("global.noperiodsfound")
                        }}</span>
                        <template v-slot:option="option">
                          {{ option.bezeichnung }} ({{ option.startdatum }}-{{
                            option.enddatum
                          }})
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          {{ option.bezeichnung }} ({{ option.startdatum }}-{{
                            option.enddatum
                          }})
                        </template>
                      </v-select>
                      <label>{{ $t("global.period") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.periodennummer"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("dashboard.semester") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.remarks"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.remarks") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.listofregistrations')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row mt-2 d-flex justify-content-between">
                  <div class="ml-4" style="display: flex">
                    <massenmutation-button
                      class="mr-2"
                      :mutatableFields="this.mutatableFields"
                      :mutatableValues="[[this.anmeldestati], [this.semester]]"
                      :mutatableLabels="this.mutatableLabels"
                      :mutationPfad="this.mutationPfad"
                      :selectedIds="this.selectedIds"
                      @mutated="refresh"
                    />
                    <button
                      class="btn btn-primary mr-2"
                      v-if="berechtigungen.m_anmeldungen.update"
                      @click="showAnmeldungUebertragen"
                    >
                      <font-awesome-icon
                        icon="fa-solid fa-sign-out-alt"
                        class="mr-2"
                      />
                      <span>{{ $t("global.transferregistration") }}</span>
                    </button>

                    <b-dropdown variant="primary mr-2">
                      <template slot="button-content">
                        <font-awesome-icon
                          icon="fa-duotone fa-print"
                          class="mr-2"
                        />
                        <em>{{ $t("global.output") }}</em>
                      </template>
                      <b-dropdown-item @click="ausgebenTeilnehmerliste">
                        {{ $t("global.participantlist") }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="ausgebenTischkarten">
                        {{ $t("global.placecards") }}
                      </b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown
                      variant="primary mr-2"
                      v-if="berechtigungen.m_korrespondenz.update"
                    >
                      <template slot="button-content">
                        <font-awesome-icon
                          icon="fa-duotone fa-envelope-open-text"
                          class="mr-2"
                        />
                        {{ $t("global.createmassmail") }}
                      </template>
                      <b-dropdown-item @click="massenmailErstellen(true)">
                        {{ $t("global.selected") }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="massenmailErstellen(false)">
                        {{ $t("global.alldisplayed") }}
                      </b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown
                      variant="primary mr-2"
                      v-if="berechtigungen.m_korrespondenz.update"
                    >
                      <template slot="button-content">
                        <font-awesome-icon
                          icon="fa-duotone fa-memo"
                          class="mr-2"
                        />
                        {{ $t("global.createserialletter") }}
                      </template>
                      <b-dropdown-item
                        @click="massenkorrespondenzErstellen(true)"
                      >
                        {{ $t("global.selected") }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="massenkorrespondenzErstellen(false)"
                      >
                        {{ $t("global.alldisplayed") }}
                      </b-dropdown-item>
                    </b-dropdown>

                    <button
                      class="btn btn-primary mr-2"
                      @click="exportToExcel(listData, 'anmeldungen')"
                    >
                      <span class="mr-2"
                        ><font-awesome-icon
                          icon="fa-regular fa-file-spreadsheet"
                      /></span>
                      {{ $t("global.export") }}
                    </button>
                  </div>
                  <div class="mr-4">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_anmeldungen.delete"
                    >
                      <font-awesome-icon
                        class="mr-1"
                        icon="fa-duotone fa-trash"
                      />
                      <span>{{ $t("global.delete") }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste belegliste-small">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-compare="sortDates"
                    sort-icon-left
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>

                    <template
                      v-slot:cell(veranstaltung.veranstaltungstyp.bezeichnung)="row"
                    >
                      <div :style="veranstaltungstypHintergrundfarbe(row.item)">
                        {{
                          row.item.veranstaltung.veranstaltungstyp
                            ? row.item.veranstaltung.veranstaltungstyp
                                .bezeichnung
                            : "-"
                        }}
                      </div>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="anmeldungen-loeschen-modal"
      @confirmed="anmeldungenLoeschen"
      :selectedIds="selectedIds"
    />

    <pdf-layer
      id="pdf-layer"
      ref="pdfLayer"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
    ></pdf-layer>

    <anmeldung-uebertragen-modal
      id="anmeldung-uebertragen-modal"
      :allowCopy="true"
      @copied="anmeldungGeaendert"
      @changed="anmeldungGeaendert"
    ></anmeldung-uebertragen-modal>

    <messagebox-count
      :headerText="$t('global.registrationsdeleted')"
      id="count-delete-alert-msgbox"
      :ok="true"
      :cancel="false"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
    />

    <loading-overlay v-show="loading"></loading-overlay>
    <alert-modal
      id="modal-alert"
      :title="alertModal.title"
      :text="alertModal.text"
      :color="alertModal.color"
      :value="alertModal.value"
      @confirmed="korrespondenzSwitch(alertModal.value)"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";
import server from "@/server";
import excelExport from "@/mixins/ExcelExport";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import AnmeldungUebertragenModal from "@/components/Modals/AnmeldungUebertragenModal";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import SingleSelectVeranstaltung from "@/components/global/SingleSelectVeranstaltung.vue";
import AlertModal from "@/components/Modals/AnmeldungAlertModal";
import MassenmutationButton from "@/components/Buttons/MassenmutationButton";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

import { LADE_AUSWAHLWERTE_ANMELDUNG } from "@/store/anmeldung/actions.type";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";
import { LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER } from "@/store/geschaeftspartner/actions.type";

export default {
  name: "Anmeldungsliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    AnmeldungUebertragenModal,
    SingleSelectPerson,
    SingleSelectVeranstaltung,
    AlertModal,
    MassenmutationButton,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("dashboard.registrations");
      },
    };
  },
  mixins: [page, dashboard, excelExport, filter],
  props: {},
  store,
  data() {
    return {
      filterRollen: this.$CONST("ROLLEN").STUDENTEN,
      pdfPath: "",
      sortBy: "person.personName",
      sortDesc: false,
      alertModal: {
        color: null,
        text: "",
        title: "",
        value: "",
      },

      fields: [
        { key: "selected", label: "" },
        {
          key: "anmeldungsdatum",
          sortable: true,
          searchable: false,
          thClass: "text-center",
          tdClass: "text-center",
          label: this.$t("global.registered"),
        },
        {
          key: "person.personName",
          sortable: true,
          label: this.$t("global.person"),
        },
        {
          key: "anmeldestatus.bezeichnung",
          sortable: true,
          label: this.$t("global.status"),
        },
        {
          key: "anmeldetyp.bezeichnung",
          sortable: true,
          label: this.$t("global.registrationtype"),
        },
        {
          key: "abschluss.bezeichnung",
          sortable: true,
          searchable: false,
          label: this.$t("global.degree"),
          thStyle: "width: 200px",
        },
        {
          key: "veranstaltung.kuerzel",
          sortable: true,
          label: this.$t("global.shortdesignation"),
        },
        {
          key: "veranstaltung.bezeichnung",
          sortable: true,
          label: this.$t("global.event"),
        },
        {
          key: "veranstaltung.veranstaltungstyp.bezeichnung",
          sortable: true,
          searchable: false,
          label: this.$t("dashboard.type"),
        },
        {
          key: "veranstaltung.niveau.bezeichnung",
          sortable: true,
          searchable: false,
          label: this.$t("global.level"),
        },

        {
          key: "rechnungsdatum",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
          label: this.$t("global.invdate"),
        },
      ],

      mutatableFields: ["Status", "Periode"],
      mutatableValues: [[], []],
      mutatableLabels: ["bezeichnung", "bezeichnung"],
      mutationPfad: "anmeldung/",
    };
  },
  computed: {
    queryParams: function () {
      var params = {};
      params.person = this.setQueryParam(this.filters.person?.id);
      params.filterPerson = this.setQueryParam(
        this.filters["person.personName"]
      );
      params.abschluss = this.setQueryParam(this.filters.abschluss);
      params.anmeldungstyp = this.setQueryParam(this.filters.anmeldungstyp);
      params.statusAnmeldung = this.setQueryParam(this.filters.statusAnmeldung);

      params.veranstaltung = this.setQueryParam(this.filters.veranstaltung?.id);
      /* if (this.veranstaltung) params.veranstaltung = this.setQueryParam(filters.veranstaltung?.id);
      else
        params.veranstaltung = this.setQueryParam(filters.veranstaltung?.id)
          ? this.setQueryParam(filters.veranstaltung?.id.join(","))
          : null; */

      params.geschlecht = this.setQueryParam(this.filters.geschlecht?.id);

      params.vstatus = this.setQueryParam(this.filters.veranstaltungsstatus);
      params.vtyp = this.setQueryParam(this.filters.veranstaltungstyp);
      params.filterVKuerzel = this.setQueryParam(
        this.filters["veranstaltung.kuerzel"]
      );
      params.filterVBez = this.setQueryParam(
        this.filters["veranstaltung.bezeichnung"]
      );
      params.filterASBez = this.setQueryParam(
        this.filters["anmeldestatus.bezeichnung"]
      );
      params.filterATBez = this.setQueryParam(
        this.filters["anmeldetyp.bezeichnung"]
      );
      params.remarks = this.setQueryParam(this.filters.remarks);
      params.periode = this.setQueryParam(this.filters.semester);
      params.semester = this.setQueryParam(this.filters.periodennummer);

      params.filterGebucht = this.setQueryParam(this.filters.gebucht);
      params.filterRGDatum = this.setQueryParam(this.filters.rechnungsdatum);

      params.filterCount = this.setQueryParam(this.filters.count);

      return params;
    },

    anmeldetypen: {
      get() {
        return this.$store.state.anmeldung.anmeldetypen;
      },
    },
    anmeldestati: {
      get() {
        return this.$store.state.anmeldung.anmeldestati;
      },
    },
    abschluesse: {
      get() {
        return this.$store.state.anmeldung.abschluesse;
      },
    },
    semester: {
      get() {
        return this.$store.state.veranstaltung.perioden;
      },
    },
    geschlechter: {
      get() {
        return this.$store.state.geschaeftspartner.geschlechter;
      },
    },
    veranstaltungsstati: {
      get() {
        return this.$store.state.veranstaltung.veranstaltungsstati;
      },
    },
    veranstaltungstypen: {
      get() {
        return this.$store.state.veranstaltung.veranstaltungstypen;
      },
    },
  },
  watch: {},
  created() {
    if (this.anmeldetypen.length == 0)
      this.$store.dispatch(`anmeldung/${LADE_AUSWAHLWERTE_ANMELDUNG}`);

    if (this.semester.length == 0)
      this.$store.dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`);

    if (this.geschlechter.length == 0)
      this.$store.dispatch(
        `geschaeftspartner/${LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER}`
      );

    this.initFilter("anmeldung-liste", "anmeldung/", true);
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
    this.setHeaders("global", [
      "",
      "registered",
      "person",
      "status",
      "registrationtype",
      "degree",
      "shortdesignation",
      "event",
      "",
      "level",
      "invdate",
    ]);

    this.setHeaders("dashboard", ["", "", "", "", "", "", "", "", "type"]);
  },
  methods: {
    refresh() {
      this.resetLoading(this);
    },
    details(anmeldungen) {
      if (anmeldungen.id) {
        this.$router.push({
          name: "anmeldung",
          params: { anmeldungid: anmeldungen.id, anzeige: 0 },
        });
      }
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "anmeldungen-loeschen-modal");
      else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noregistrationselecteddeletion"),
        });
      }
    },

    anmeldungenLoeschen() {
      this.delete("anmeldung/", this.selectedIds);
    },

    ausgebenTeilnehmerliste: function () {
      if (this.selectedIds.length > 0) {
        this.selectedIds = Array.from(new Set(this.selectedIds));
        let filename = new Intl.DateTimeFormat("ch").format(new Date());

        this.pdfPath =
          server.url +
          `/pdfgenerator/pdf.act?filename=AL${filename}.pdf&report=/pdfgenerator/teilnehmer.htm&ids=${this.selectedIds}`;
        this.$refs.pdfLayer.show();
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noregistrationselected"),
        });
      }
    },

    ausgebenTischkarten: function () {
      this.pdfPath =
        server.url +
        "/pdf/tischkarten-pdf-erzeugen-anmeldung-/pdf.act?ids=" +
        this.selectedIds.join(",");
      this.$refs.pdfLayer.show();
    },

    veranstaltungstypHintergrundfarbe(item) {
      let veranstaltungstyp = item.veranstaltung.veranstaltungstyp;

      if (veranstaltungstyp) {
        //Fach:
        if (veranstaltungstyp.id == "174D91487D4") {
          return { backgroundColor: "#EAE8AC", textAlign: "center" };
        }
        //Bildungsgang (Semester):
        else if (veranstaltungstyp.id == "174D91487DA") {
          return { backgroundColor: "#84D0E2", textAlign: "center" };
        }
        //Kurs:
        else if (veranstaltungstyp.id == "174D91487E8") {
          return { backgroundColor: "#C3C4E2", textAlign: "center" };
        } else {
          return { backgroundColor: "#DEEDF6", textAlign: "center" };
        }
      } else {
        return { textAlign: "center" };
      }
    },

    showAnmeldungUebertragen() {
      this.$bus.$emit("openAnmeldungUebertragenModal", this.selectedIds);
    },

    anmeldungGeaendert() {
      this.$notify({
        type: "success",
        title: this.$t("notification.actionsuccessful"),
        text: this.$t("notification.registrationssuccessfullytransferred"),
      });

      this.resetLoading(this);
    },

    setFilterPerson(person) {
      this.$set(this.filters, "person", person);
    },

    setFilterVeranstaltung(veranstaltung) {
      this.$set(this.filters, "veranstaltung", veranstaltung);
    },

    showAlertModal(title, text, color, value) {
      this.alertModal.title = title;
      this.alertModal.text = text;
      this.alertModal.color = color;
      this.alertModal.value = value;
      $("#modal-alert").modal("show");
    },
    korrespondenzSwitch(value) {
      console.log(value);
      if (value == "sBrief") {
        this.massenkorrespondenzErstellen(true, true);
      } else if (value == "mMail") {
        this.massenmailErstellen(true, true);
      }
    },
    massenkorrespondenzErstellen(selected, exec) {
      if (!selected || this.selectedIds.length > 0) {
        let tempList = selected
          ? this.selectedObjects.map((item) =>
              item.veranstaltung.id == this.selectedObjects[0].veranstaltung.id
                ? item.person.id
                : item.person.id
            )
          : this.$refs.selectableTable.items.map((item) =>
              item.veranstaltung.id ==
              this.$refs.selectableTable.items[0].veranstaltung.id
                ? item.person.id
                : item.person.id
            );

        let veranstaltungList = selected
          ? this.selectedObjects.map((item) =>
              item.veranstaltung.id == this.selectedObjects[0].veranstaltung.id
                ? true
                : false
            )
          : this.$refs.selectableTable.items.map((item) =>
              item.veranstaltung.id ==
              this.$refs.selectableTable.items[0].veranstaltung.id
                ? true
                : false
            );

        if (veranstaltungList.filter((bool) => bool == false).length > 0) {
          // Es hat Personen mit einer anderen Veranstaltung in der Liste
          this.showAlertModal(
            this.$t("global.warning") + ": ",
            this.$t(
              "global.youselectedcrosseventonlygenericmasslettersmailscreated"
            ),
            null,
            this.$t("global.sletter")
          );
        } else {
          exec = true;
        }
        if (exec == true) {
          let json = {
            empfaenger: [],
            person: [], // ist de facto person. Noch unsauber im Backend
            isMassKorrespondenz: true,
            anmeldung: this.selectedIds[0], // Die erste Anmeldung wird für die Verknüpfung mitgegeben, damit die Serienfelder gehen
            versand: {
              erstelltvon: this.angemeldeterMitarbeiter,
              datum: new Intl.DateTimeFormat("ch-DE").format(new Date()),
              ort: "",
              betreff: this.$t("global.newserialletter"),
            },
            inhalt: "",
            zeitpunkt: new Intl.DateTimeFormat("ch-DE").format(new Date()),
          };

          json.empfaenger = tempList;
          json.person = tempList;

          /* if (json.empfaenger.length == 0) {
          this.$notify({
            type: "warn",
            title: "Aktion fehlgeschlagen",
            text: "Ausgewählte Anmeldungen enthalten keine verknüpfte Person",
          });
          return;
        }*/

          Api.post("korrespondenz/", json, { params: {} }).then((response) => {
            this.loading = true;
            Api.post("korrespondenz/empfaenger/", json, {
              params: {
                korrespondenz: response.data.id,
                isPerson: true,
                isMassCorr: true,
              },
            }).then((empfresponse) => {
              this.loading = false;
              this.$router.push({
                name: "korrespondenz",
                params: {
                  id: response.data.id,
                  anzeige: 0,
                },
              });
            });
          });
        }
      } else
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.nopersonselected"),
        });
    },

    massenmailErstellen(selected, exec) {
      if (!selected || this.selectedIds.length > 0) {
        let tempList = selected
          ? this.selectedObjects.map((item) =>
              item.veranstaltung.id == this.selectedObjects[0].veranstaltung.id
                ? item.person.id
                : item.person.id
            )
          : this.$refs.selectableTable.items.map((item) =>
              item.veranstaltung.id ==
              this.$refs.selectableTable.items[0].veranstaltung.id
                ? item.person.id
                : item.person.id
            );

        let veranstaltungList = selected
          ? this.selectedObjects.map((item) =>
              item.veranstaltung.id == this.selectedObjects[0].veranstaltung.id
                ? true
                : false
            )
          : this.$refs.selectableTable.items.map((item) =>
              item.veranstaltung.id ==
              this.$refs.selectableTable.items[0].veranstaltung.id
                ? true
                : false
            );

        if (veranstaltungList.filter((bool) => bool == false).length > 0) {
          // Es hat Personen mit einer anderen Veranstaltung in der Liste
          this.showAlertModal(
            this.$t("global.warning") + ": ",
            this.$t(
              "global.youselectedcrosseventonlygenericmasslettersmailscreated"
            ),
            null,
            this.$t("global.mmail")
          );
        } else {
          exec = true;
        }
        if (exec == true) {
          let json = {
            empfaenger: tempList,
            isMassMail: true,
            inhalt: "",
            zeitpunkt: new Intl.DateTimeFormat("ch").format(new Date()),
          };

          Api.post("mail/", json, { params: {} }).then((response) => {
            this.$router.push({
              name: "mail",
              params: {
                id: response.data.id,
                anzeige: 0,
              },
            });
          });
        }
        //}
      } else
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noregistrationselected"),
        });
    },
  },
};
</script>

<style>
</style>
